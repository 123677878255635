import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"hakkimizda"} />
		<Helmet>
			<title>
				About Us | VideoSanat Akademisi'ni Keşfedin
			</title>
			<meta name={"description"} content={"Video sanatının sınırlarını zorlayın ve kendi hikayenizi bizimle birlikte yazın"} />
			<meta property={"og:title"} content={"About Us | VideoSanat Akademisi'ni Keşfedin"} />
			<meta property={"og:description"} content={"Video sanatının sınırlarını zorlayın ve kendi hikayenizi bizimle birlikte yazın"} />
			<meta property={"og:image"} content={"https://vexnubet.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vexnubet.com/img/6979672.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vexnubet.com/img/6979672.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vexnubet.com/img/6979672.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Bizim Hikayemiz
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="80%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					VideoSanat Akademisi, yaratıcılığın ve teknolojinin kesiştiği bir noktada, herkesin içindeki sanatçıyı ortaya çıkarmayı amaçlayan bir platform olarak doğdu. Video düzenleme ve prodüksiyonun sadece teknik bir beceri olmadığını, aynı zamanda bir sanat formu olduğunu vurgulamak isteyen bir grup vizyoner tarafından kurulduk. Biz, anlatılmak istenen hikayelere değer katabilecek, duyguları harekete geçirebilecek içerikler yaratma gücüne inanıyoruz.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						VideoSanat Farkı
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Sanat ve teknolojiyi birleştiren eşsiz yaklaşımımız, kursiyerlerimizin yalnızca teknik beceriler kazanmalarını değil, aynı zamanda yaratıcı vizyonlarını genişletmelerini sağlar. Modern teknolojiler ve yenilikçi öğrenme metodolojileri ile donatılmış kurslarımız, her bir öğrencinin potansiyelini maksimum düzeyde ortaya çıkarmak için tasarlanmıştır.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Neden VideoSanat Akademisi'ni Tercih Etmelisiniz?
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Yaratıcı Yaklaşım: Her bir projede sanatsal ifadeyi ön plana çıkarırız.
						<br /><br />
						Kişiye Özel Eğitim: Öğrenme stilinize ve hedeflerinize uygun esnek eğitim seçenekleri sunarız.
						<br /><br />
						Sektörel Bağlantılar: Eğitimlerimiz, sektörle iç içe gerçek dünya tecrübeleri sunar.
						<br /><br />
						Dinamik Topluluk: Benzersiz yaratıcılar topluluğumuzda ilham alın ve işbirliği yapın.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Hikayenizi Bizimle Yazın
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					VideoSanat Akademisi'nde, her bir kareyle kendi hikayenizi anlatma fırsatını yakalayın. Siz de bu yaratıcı yolculuğun bir parçası olun ve sanatınızla dünyayı değiştirme gücünü keşfedin.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://vexnubet.com/img/3.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://vexnubet.com/img/4.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					VideoSanat Akademisi – Hayallerinizi Gerçeğe Dönüştüren Yer
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});